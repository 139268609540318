import styles from './Navbar.module.css'
import logo from '../../assets/image/logo-nav.png'
// import iconMedium from './image/icons8-medio-512.svg'
import iconGitHub from './image/icons8-github-500.svg'

export const Navbar = ({handleTagH3StoriesClick, handletagH3ConceptClick}) => {

  return (
    <div className={styles.navBar}>
          <a className={styles.iconoNav} href="https://friki.io/"
            ><img src={logo} alt="lampara"/>
            </a>
          <nav>
            <ul>
              <li>
                {/* <a href="#" onClick={onParagraphClick}>Stories</a> */}
                <button  className={styles.buttomNav} onClick={handleTagH3StoriesClick }>Stories</button>
              </li>
              <li>
                {/* <a href="https://www.google.com/">Concept</a> */}
                <button  className={styles.buttomNav} onClick={handletagH3ConceptClick }>Concept</button>
              </li>
              {/* <li><a href="https://medium.com/@jitapichab/kafka-chaos-experiments-using-chaostoolkit-kafka-32484244ae1c"><img src={iconMedium} alt="iconoMedium" /></a></li> */}
              <li><a href="https://github.com/friki-io"><img src={iconGitHub} alt="iconoMedium" /></a></li>
            </ul>
          </nav>
    </div>
  )
}
