import style from "./mainConten.module.css";
import storie from "./image/love-friki.webp";
import storie2 from "./image/Computer-friki.webp";
import { useEffect, useRef } from "react";

export const MainConten = ({ setRefTag }) => {

  const tagH3Stories = useRef(null);
  const tagH3Concept = useRef(null);

  useEffect(() => {

    setRefTag({
      tagH3Stories,
      tagH3Concept: tagH3Concept,
    });
    
  }, [setRefTag, tagH3Stories, tagH3Concept]);

  return (
    <div className={style.container}>
      <div>
        <h1 className={style.titleH1}>
           <span>Friki</span>
        </h1>
        <h2 ref={tagH3Stories} className={style.titleH2}>
          This story is crazy, this story is Friki
        </h2>
        {/* <h3 className={style.titleH3}>Stories</h3> */}
      </div>
      <div className={style.containerP}>
        <div>
          <p className={style.p1}>
            Once upon a time, in a park at sunset, there was a curious character
            named Friki. Friki was no ordinary person; he was a Site Reliability
            Engineer (SRE) with only one hair on his head, the result of so much
            thinking and solving infrastructure incidents. After months of
            chatting on social media, he had finally gotten a date with a very
            beautiful and unique lightbulb he had met online.
          </p>
          <img className={style.imgStorie1} src={storie} alt="friki-love" />
          <p>
            That day, after a sunny afternoon full of laughter and charming
            conversations, it seemed that Friki was finally going to get a kiss.
            He was sitting on a park bench, nervous and excited, next to the
            enchanting lightbulb who, with her sparkling eyes, was slowly
            leaning towards him. The sun was setting on the horizon, painting
            the sky with shades of orange and pink, creating the perfect setting
            for that long-awaited moment. Just as their faces were about to
            touch, Friki’s phone began to ring with a loud alarm. The screen
            flashed large red letters: “IT Incident - Priority 1.” The sound
            interrupted the magic of the moment. Friki jumped off the bench, his
            expression shifting from happiness to concern in an instant. “Oh no,
            not now! I have to take care of this!” Friki exclaimed, looking at
            his phone in despair. The lightbulb, astonished, confused, and a
            little disconcerted, asked, “What’s wrong, Friki?” Friki said he was
            sorry, but he had to deal with an infrastructure issue, and without
            wasting any time, he pulled his laptop out of his backpack, quickly
            connected, and began to resolve the incident.
          </p>
        </div>
        <div>
          <p className={style.p2}>
            While Friki was immersed in solving the problem, the enchanting
            lightbulb looked at her watch and realized that time had flown by.
            With a sigh, she got up from the bench and told Friki she had to
            leave. “I’m sorry, Friki, but it’s getting late. We’ll see each
            other another time,” she said with a sad smile. Friki, caught up in
            lines of code and commands, hadn’t managed to resolve the incident
            by the time the enchanting lightbulb left, leaving him alone in the
            park.
          </p>
          <img className={style.imgStorie1} src={storie2} alt="friki-love" />
          <p className={style.p2}>
            Friki’s life, like that of many who work in IT, is filled with
            moments interrupted by urgent alerts during his on-call rotation
            week. A trip, a special celebration, an incredible rave, a date with
            an enchanting lightbulb, or even the possibility of an amazing kiss,
            have all been interrupted at some point by an alert announcing an
            infrastructure incident. Although working in IT is exciting and full
            of challenges, those on-call rotations often turn the most
            anticipated moments into postponed opportunities. Friki dreams of a
            world without on-call alerts and late-night maintenance windows,
            where, despite being on call, he can have the peace of mind to enjoy
            his activities and savor those special moments without an unexpected
            alert ruining them.
          </p>
        </div>
      </div>
      <div>
        <h3 /* ref={textRef2} */ ref={tagH3Concept} className={style.titleH3a}>
          Concept
        </h3>
        <p className={style.p3}>
          Friki was born as a hobby aimed at creating open-source projects that
          help reduce the burden of on-call rotations. It’s the passion of
          sitting down at the computer and developing ideas that reduce the
          number of alerts we need to address during an on-call rotation and
          minimize the need for maintenance windows requiring staff on-site,
          ensuring changes are successfully implemented without any issues.
          Friki is a character deeply passionate about software and learning new
          technologies. When Friki isn’t working or exploring the tech world,
          you’ll find him traveling, attending parties, learning new sports,
          enjoying coffee, wine, and enriching conversations, visiting museums,
          spending time with family, and appreciating the countless
          possibilities the world has to offer, always seeking a fulfilling life
          filled with unforgettable moments.
        </p>
        <p className={style.p3}>
          On-call rotation is a critical aspect of IT operations, where
          engineers take turns being on standby to respond to urgent incidents
          that could impact system stability. While essential, it often leads to
          disruptions in personal life, as alerts can arrive at any hour,
          requiring immediate attention. To mitigate the impact of on-call
          rotations, several practices and technologies come into play. Chaos
          engineering, for instance, involves proactively testing systems to
          identify potential points of failure before they cause issues, thereby
          reducing unexpected incidents. Additionally, the automation of
          nightshift operations helps manage routine tasks and monitor systems
          without constant human intervention, significantly reducing the number
          of times engineers need to work during the early hours. Observability
          tools provide deep insights into system behavior, allowing teams to
          detect and address issues before they escalate into critical
          incidents. Automated responses to repetitive alerts further minimize
          interruptions, ensuring that engineers are only alerted when truly
          necessary. By integrating these strategies, we can significantly
          reduce the frequency of on-call alerts, creating a more balanced and
          less disruptive experience for those in rotation.
        </p>
        <p className={style.p3}>
          Wow, what you just read in the previous paragraph sounds like a lot of
          fun to do, doesn’t it? That’s exactly what Friki is all about—finding
          joy in creating solutions, proposing new ideas, and sharing with
          software communities the ways we can reduce on-call interruptions.
          Because let’s be honest, building software to minimize alerts is a lot
          of fun, but responding to those alerts? Not so much! 🤪
        </p>
      </div>
    </div>
  );
};
