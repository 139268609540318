// import logo from './logo.svg';
import "./App.css";
import { Navbar } from "./components/navbar/Navbar";
import { SideBar } from "./components/sideBar/SideBar";
import { MainConten } from "./components/mainContent/MainConten";
import { useState, } from "react";

function App() {


  // let tagH3Stories, tagH3Concept;

  const [refTag, setRefTag] = useState({
    tagH3Stories: null,
    tagH3Concept: null,
  })



  const handleTagH3StoriesClick = ()=>{
    if(refTag.tagH3Stories.current) {
      refTag.tagH3Stories.current.scrollIntoView({ behavior: 'smooth' })
    } return 
      
  }
  const handletagH3ConceptClick = ()=>{
    if(refTag.tagH3Concept.current) {
      refTag.tagH3Concept.current.scrollIntoView({ behavior: 'smooth' })
    } return 
      
  }

  return (
    <div className="container-app">
      <div className="grid-app box">
        <Navbar   handleTagH3StoriesClick={handleTagH3StoriesClick}  handletagH3ConceptClick={handletagH3ConceptClick}/>
        <MainConten setRefTag={setRefTag} />
        <SideBar  />
      </div>
    </div>
  );
}

export default App;
